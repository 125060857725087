import {
  ChannelType,
  CreateCheckoutFromCurrentCartRequest,
  CreateCheckoutResponse,
} from '@wix/ambassador-ecom-v1-cart/types';
import {
  CreateOrderRequest,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/types';
import { CartFlow, ReservedPaymentOptionIds } from '../../../../types/types';
import { CreateActionParams } from '../actions';

export type OnCartCTAClick = (cartFlow: CartFlow) => Promise<void>;

export function createOnCartCTAClickAction({
  context,
  getControllerState,
}: CreateActionParams): OnCartCTAClick {
  return async (cartFlow: CartFlow) => {
    const { wixSdkAdapter, formApi } = context;
    const [state] = getControllerState();
    const {
      paymentDetails,
      selectedPaymentOptionId,
      cartModal,
      memberships,
    } = state;
    if (cartFlow === CartFlow.ADD_MORE_SESSIONS) {
      await wixSdkAdapter.navigateAfterAddToCart();
    } else if (cartFlow === CartFlow.CHECKOUT) {
      const isSingleItemOnCart = cartModal?.lineItems?.length === 1;
      const isMembershipOptionSelected = memberships?.eligibleMemberships?.find(
        (membership) => selectedPaymentOptionId === membership.id,
      );
      const isCustomPrice =
        paymentDetails.priceText &&
        selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
      const isFreePrice =
        paymentDetails.isFree &&
        selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
      const shouldGoToTYP =
        isMembershipOptionSelected || isCustomPrice || isFreePrice;

      if (isSingleItemOnCart) {
        const createCheckoutFromCurrentCartRequest: CreateCheckoutFromCurrentCartRequest = {
          channelType: ChannelType.WEB,
        };
        const checkoutFromCartResponse: CreateCheckoutResponse = await formApi.createCheckoutFromCurrentCart(
          createCheckoutFromCurrentCartRequest,
        );
        if (!checkoutFromCartResponse.checkoutId || !shouldGoToTYP) {
          await wixSdkAdapter.navigateToEcomCart();
        }
        if (shouldGoToTYP) {
          const createOrderRequest: CreateOrderRequest = {
            id: checkoutFromCartResponse.checkoutId,
          };
          const orderFromCheckoutResponse: CreateOrderResponse = await formApi.createOrderFromCheckout(
            createOrderRequest,
          );
          if (orderFromCheckoutResponse.orderId) {
            await wixSdkAdapter.navigateToEcomThankYouPage({
              orderId: orderFromCheckoutResponse.orderId,
            });
          }
        }
      } else {
        await wixSdkAdapter.navigateToEcomCart();
      }
    } else {
      throw new Error('CartFlow cannot be undefined after adding to cart');
    }
  };
}
